<template>
    <div class="overlay open" @click="close"></div>
    <div class="message-thanks message-company" >
    <svg class="close" @click="close" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6561 1.33776L6.99387 6L11.6561 10.6622C11.788 10.7941 11.8621 10.973 11.8621 11.1595C11.8621 11.3461 11.788 11.525 11.6561 11.6569C11.5242 11.7887 11.3453 11.8628 11.1588 11.8628C10.9723 11.8628 10.7934 11.7887 10.6615 11.6569L5.99926 6.99461L1.33701 11.6569C1.20512 11.7887 1.02623 11.8628 0.839707 11.8628C0.653182 11.8628 0.474295 11.7887 0.342401 11.6569C0.210508 11.525 0.136411 11.3461 0.136411 11.1595C0.136411 10.973 0.210508 10.7941 0.342401 10.6622L5.00464 6L0.342402 1.33776C0.210508 1.20586 0.13641 1.02698 0.13641 0.840451C0.13641 0.653925 0.210508 0.475039 0.342401 0.343146C0.474295 0.211252 0.653181 0.137154 0.839707 0.137154C1.02623 0.137154 1.20512 0.211252 1.33701 0.343146L5.99926 5.00539L10.6615 0.343146C10.7934 0.211252 10.9723 0.137155 11.1588 0.137155C11.3453 0.137155 11.5242 0.211252 11.6561 0.343146C11.788 0.475039 11.8621 0.653925 11.8621 0.840451C11.8621 1.02698 11.788 1.20586 11.6561 1.33776Z" fill="#0A3041"/>
    </svg> 
   <img class="m-auto" src="../../assets/img/global/Company-mess.png" alt="">
   
   <p class="mb-0" style="color: #0A3041;font-size: 20px;font-weight: 500;">{{ this.$i18n.locale == 'ar' ? 
   'معلومات شركتك غير كافية...'
    :
    'Your company information is not enough...' }}
    </p>
    <span class="mt-0 mb-2" style="color: #225476;font-size: 28px;">{{ this.$i18n.locale == 'ar' ? 
   'الرجاء اضافة بيانات شركتك !'
    :
    'Please add your company information !' }}
    </span>
    <p>
        {{ 
        this.$i18n.locale == 'ar' ? 'قم باضافة معلومات شركتك للاستئناف و الحصول على الخدمات و الباقات التي تناسبك طبيعة عملك ' : 'Add your company information to resume and obtain the services and packages that suit the nature of your work'   
        }}
    </p>
    <div class="d-flex justify-content-center mt-3">
        <router-link to="/profile/companies" class="btn-main">
        {{ this.$i18n.locale == 'ar' ? 'الانتقال الى منشاتي' : 'Moving to my company' }}
    </router-link>
    </div>
    </div>
</template>
<script>
export default {
 data() {
   return {
   };
 },
 methods: {
        close(){
            this.$emit('hide')
        }
    },
};
</script>